import React from 'react';
import './App.css';
import 'font-awesome/css/font-awesome.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-links" >
        <a href="https://github.com/larsh0103" target="_blank" rel="noopener noreferrer" style={{color:"#775E4D"}}>
        <i className="fa fa-github" /> GitHub
        </a>
          <a href="https://medium.com/@larsvagnes" target="_blank" rel="noopener noreferrer" style={{color:"#775E4D"}}>
          <i className="fa fa-medium" /> Medium
          </a>
          <a href="https://twitter.com/vagneslars" target="_blank" rel="noopener noreferrer" style={{color:"#775E4D"}}>
          <i className="fa fa-twitter" /> Twitter
          </a>
          <a href="https://simli.com" target="_blank" rel="noopener noreferrer" style={{color:"#775E4D"}}>
          <i className="fa fa-rocket" /> Simli
          </a>
          <a href="https://transcribegenie.com" target="_blank" rel="noopener noreferrer" style={{color:"#775E4D"}}>
          <i className="fa fa-fire" /> Transcribe Genie
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;